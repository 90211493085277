import enJson from "../locales/en.json";
import arJson from "../locales/ar.json";

export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZmxhdm91cnMiLCJhIjoiY2xhNWlzbjR3MDh5cTN2cHRlaTl1dzB5YSJ9.l_e8qGdkNwO2TVQNre90lg";

export const PUSHER_APP_ID = "1289997";
export const PUSHER_APP_KEY = "bff6006b3e7be62c10d6";
export const PUSHER_APP_SECRET = "993424303c9b5692ffef";
export const PUSHER_APP_CLUSTER = "apps";

// export const ENV_TYPE = "LOCAL";
// export const ZATCA_BASE_URL = "https://api.flavours.sa/dev";
// export const BASE_URL = "http://localhost:8000";
// // export const BASE_URL = "http://flavourspos.test";
// export const BASE_API_URL = `${BASE_URL}/api`;
// export const BASE_CHANNEL_URL = `${BASE_URL}/broadcasting`;
// export const POS_PAGE_URL = "http://localhost:3000";
// export const LANDING_PAGE_URL = "https://order-dev.flavours.sa";
// export const TAP_PAYMENT_SECRET_KEY = "sk_test_TrMzepjXOv1nwsUqhDN0QFCV";
// export const TAP_PAYMENT_PUBLIC_KEY = "pk_test_qgDb7PL2THJi5YKS3s6ZWwyx";
// export const TAP_PAYMENT_MERCHANT_ID = "23573117";
// export const TAP_PAYMENT_MERCHANT_DOMAIN = "https://order-dev.flavours.sa";
// export const TIDIO_PUBLIC_KEY = "nsopjzdl1vdcgjtp0uwwfdvd8cczp0zw";
// export const GOOGLE_ANALYTICS_MEASUREMENT_ID = "G-CCGMKPLG4T";
// export const BALLURH_API_TOKEN = "hOXhXEthnZ4cCAjd0lCRBG.2RUohKyrMJpzJHs";

// export const PUSHER_APP_CONFIG = {
//   wsHost: "127.0.0.1",
//   wsPort: 6001,
//   forceTLS: false,
//   encrypted: true,
//   disableStats: true,
//   enabledTransports: ["ws", "wss"],
//   cluster: PUSHER_APP_CLUSTER,
// };

export const ENV_TYPE = "DEVELOPMENT";
export const ZATCA_BASE_URL = "https://dev.flavours.sa";
export const BASE_URL = "https://dev.flavours.sa";
export const BASE_API_URL = `${BASE_URL}/api`;
export const BASE_CHANNEL_URL = `${BASE_URL}/broadcasting`;
export const POS_PAGE_URL = "https://pos-dev.flavours.sa";
export const LANDING_PAGE_URL = "https://order-dev.flavours.sa";
export const TAP_PAYMENT_SECRET_KEY = "sk_test_TrMzepjXOv1nwsUqhDN0QFCV";
export const TAP_PAYMENT_PUBLIC_KEY = "pk_test_qgDb7PL2THJi5YKS3s6ZWwyx";
export const TAP_PAYMENT_MERCHANT_ID = "23573117";
export const TAP_PAYMENT_MERCHANT_DOMAIN = "https://order-dev.flavours.sa";
export const TIDIO_PUBLIC_KEY = "nsopjzdl1vdcgjtp0uwwfdvd8cczp0zw";
export const GOOGLE_ANALYTICS_MEASUREMENT_ID = "G-CCGMKPLG4T";
export const BALLURH_API_TOKEN = "hOXhXEthnZ4cCAjd0lCRBG.2RUohKyrMJpzJHs";

export const PUSHER_APP_CONFIG = {
  wsHost: "websocket.flavours.sa",
  wsPort: 6001,
  forceTLS: true,
  encrypted: true,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
  cluster: PUSHER_APP_CLUSTER,
};

// export const ENV_TYPE = "PRODUCTION";
// export const ZATCA_BASE_URL = "https://api.flavours.sa/pos";
// export const BASE_URL = "https://api.flavours.sa/pos";
// export const BASE_API_URL = `${BASE_URL}/api`;
// export const BASE_CHANNEL_URL = `${BASE_URL}/broadcasting`;
// export const POS_PAGE_URL = "https://pos.flavours.sa";
// export const LANDING_PAGE_URL = "https://order.flavours.sa";
// export const TAP_PAYMENT_SECRET_KEY = "sk_live_FKYghL1kWTD6pmoAIdjVGuyv";
// export const TAP_PAYMENT_PUBLIC_KEY = "pk_live_JMfaud12B7kIWDlxQSR0U5Tr";
// export const TAP_PAYMENT_MERCHANT_ID = "23573117";
// export const TAP_PAYMENT_MERCHANT_DOMAIN = "https://order.flavours.sa";
// export const TIDIO_PUBLIC_KEY = "nsopjzdl1vdcgjtp0uwwfdvd8cczp0zw";
// export const GOOGLE_ANALYTICS_MEASUREMENT_ID = "G-33WYNTHX5J";
// export const BALLURH_API_TOKEN = "hOXhXEthnZ4cCAjd0lCRBG.2RUohKyrMJpzJHs";

// export const PUSHER_APP_CONFIG = {
//   wsHost: "socket.flavours.sa",
//   wsPort: 6001,
//   forceTLS: true,
//   encrypted: true,
//   disableStats: true,
//   enabledTransports: ["ws", "wss"],
//   cluster: PUSHER_APP_CLUSTER,
// };

// export const ENV_TYPE = "UAT";
// export const ZATCA_BASE_URL = "https://api-uat.flavours.sa";
// export const BASE_URL = "https://api-uat.flavours.sa";
// export const BASE_API_URL = `${BASE_URL}/api`;
// export const BASE_CHANNEL_URL = `${BASE_URL}/broadcasting`;
// export const POS_PAGE_URL = "https://pos.flavours.sa";
// export const LANDING_PAGE_URL = "https://order.flavours.sa";
// export const TAP_PAYMENT_SECRET_KEY = "sk_live_FKYghL1kWTD6pmoAIdjVGuyv";
// export const TAP_PAYMENT_PUBLIC_KEY = "pk_live_JMfaud12B7kIWDlxQSR0U5Tr";
// export const TAP_PAYMENT_MERCHANT_ID = "23573117";
// export const TAP_PAYMENT_MERCHANT_DOMAIN = "https://order.flavours.sa";
// export const TIDIO_PUBLIC_KEY = "nsopjzdl1vdcgjtp0uwwfdvd8cczp0zw";
// export const GOOGLE_ANALYTICS_MEASUREMENT_ID = "G-33WYNTHX5J";
// export const BALLURH_API_TOKEN = "hOXhXEthnZ4cCAjd0lCRBG.2RUohKyrMJpzJHs";

// export const PUSHER_APP_CONFIG = {
//   wsHost: "socket.flavours.sa",
//   wsPort: 6001,
//   forceTLS: true,
//   encrypted: true,
//   disableStats: true,
//   enabledTransports: ["ws", "wss"],
//   cluster: PUSHER_APP_CLUSTER,
// };

export const CURRENCY_SHORT = "SR";
export const LANGUAGES = { EN: "en", AR: "ar" };
export const LANGUAGE_RESOURCES = [
  {
    code: LANGUAGES.EN,
    name: "English",
    json: enJson,
  },
  {
    code: LANGUAGES.AR,
    name: "العربية",
    json: arJson,
  },
];
export const TIME_PERIODS = {
  ALL_TIME: "ALL_TIME",
  CUSTOM: "CUSTOM",

  LAST_DAY: "LAST_DAY",
  LAST_WEEK: "LAST_WEEK",
  LAST_MONTH: "LAST_MONTH",
  LAST_YEAR: "LAST_YEAR",
};
export const GENDERS = {
  MALE: "MALE",
  FEMALE: "FEMALE",
};
